// @ts-ignore
import NoSsr from 'vue-no-ssr'
import Vue from 'vue'
import { META_COUNTRIES } from '@/constants/meta'
import { PART_TIME, PositionType, POSITION_TYPES } from '@/constants/vacancies/positionTypes'
import { Stages, Statuses } from '@/constants/vacancies/statuses'
import { VACANCIES, VACANCY_DETAILS, VACANCY_DETAILS_BY_ID } from '@/constants/routes'
import responseMixin from '@/mixins/responseMixin'
import rolebleMixin from '@/mixins/rolebleMixin'
import canonicalMixin from '@/mixins/canonicalMixin'
import { stripDescriptionTags } from '@/utils-ts/strings'
import { addMonths, formatDate } from '@/utils/date'
import ErrorMatcher from '@/utils/ErrorMatcher'
import { parseTemplate } from '@/utils/parser'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import JobDescription from '../components/JobDescription/JobDescription.vue'
import ActionsCard from '../components/ActionsCard/ActionsCard.vue'
import CustomerInfo from '../components/CustomerInfo/CustomerInfo.vue'
import ShareBlock from '../components/ShareBlock/ShareBlock.vue'
import MobileActionsCard from '../components/MobileActionsCard/MobileActionsCard.vue'
import JobMoreSection from '../components/JobMoreSection/JobMoreSection.vue'
import { SalaryTypes } from '@/constants/vacancies/SalaryTypes'
import { DATE_TIME_FORMAT_WITHOUT_SEC } from '@/constants/utils/date'
import Skill from '@/models-ts/Skill'
import { AppState } from '@/store/shared/modules/app/types'
import { RootState } from '@/store'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import VacancyLocation from '@/models-ts/vacancies/VacancyLocation'
import { userUpdateMeta } from '@/api/users'
import { vacancyUnArchive } from '@/api/vacancies'
import snackMixin from '@/mixins/snackMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, rolebleMixin, canonicalMixin, snackMixin],
  components: {
    ActionsCard,
    CustomerInfo,
    JobDescription,
    JobMoreSection,
    MobileActionsCard,
    ShareBlock,
    NoSsr,
  },
  data () {
    return {
      activeTab: 'description',
      VACANCIES
    }
  },
  computed: {
    ...mapState<RootState>('app', {
      isLoggedIn: (state: AppState) => state.authorized,
    }),
    ...mapState('vacancyDetails', {
      prefetched: (state: any) => state.prefetched,
    }),
    ...mapGetters({
      vacancy: 'vacancyDetails/vacancy',
      vacancyId: 'vacancyDetails/vacancyId',
      isLoading: 'vacancyDetails/isLoading',
      isLoaded: 'vacancyDetails/isLoaded',
      isOwner: 'vacancyDetails/isOwner',
    }),
    positionType () {
      return POSITION_TYPES[this.vacancy.position_type as PositionType]?.name
    },
    remoteInfo () {
      return this.vacancy.remoteInfo
    },
    inArchive () {
      return this.vacancy?.stage === Stages.COMPLETED || this.vacancy?.stage === Stages.ARCHIVED
    },
    isPublished () {
      return this.vacancy.status === Statuses.PUBLISHED
    },
    name () {
      return this.vacancy?.name
    },
    customerName () {
      return this.vacancy?.relations?.Customer?.name
    },
    metaTitle () {
      return this.vacancy
        ? `${this.name} Job at ${this.customerName || 'Customer'}, ${this.positionType}, ${this.remoteInfo}.`
        : 'Loading'
    },
    metaDescription () {
      return this.vacancy
        // eslint-disable-next-line max-len
        ? `Apply for ${this.name} full time job at ${this.customerName || 'Customer'}. Find a Cryptocurrency job at LaborX and get paid in crypto.`
        : 'Loading'
    },
  },
  watch: {
    '$route.params.id': {
      handler (newJob, oldJob) {
        if (newJob && String(newJob) !== String(oldJob)) {
          this.clearVacancy()
          this.load({ id: newJob, slug: this.$route.params.slug })
        }
      }
    },
  },
  async prefetch () {
    try {
      if (process.server) {
        if (!this.isLoggedIn) {
          await Promise.all([
            this.load({ id: this.$route.params.id, slug: this.$route.params.slug }),
            this.getCountries()
          ])
          await this.loadMoreVacancies()
          this.setPrefetched(true)
        } else {
          this.$store.commit('responseHeaders/disableCacheControl')
        }
      }
      if (this.$route.name === VACANCY_DETAILS_BY_ID && this.vacancy) {
        this.setRedirect(parseTemplate(VACANCY_DETAILS, { id: this.vacancy.id, slug: this.vacancy.slug }))
      }
    } catch (e) {
      if (ErrorMatcher.isGone(e) || ErrorMatcher.isNotFound(e) || ErrorMatcher.isForbidden(e) || ErrorMatcher.isConflict(e)) {
        this.setNotFound(true)
      } else {
        console.error('Fetch vacancy details error:', e)
      }
    }
  },
  async created () {
    try {
      if (process.client) {
        await this.checkExtendPublication()
        if (!this.countriesIsLoaded) {
          this.getCountries()
        }
        if (!this.prefetched) {
          if (String(this.vacancy?.id) !== String(this.vacancyId)) {
            await this.load({ id: this.$route.params.id, slug: this.$route.params.slug })
            await this.loadMoreVacancies()
          }
          if (this.$route.name === VACANCY_DETAILS_BY_ID) {
            this.$router.replace({ name: VACANCY_DETAILS, params: { id: this.vacancy.id, slug: this.vacancy.slug } })
          }
        } else {
          this.setPrefetched(false)
        }
      }
    } catch (e) {
      if (ErrorMatcher.isGone(e) || ErrorMatcher.isNotFound(e) || ErrorMatcher.isForbidden(e) || ErrorMatcher.isConflict(e)) {
        this.setNotFound(true)
      } else {
        this.parseError(e)
      }
    }
  },
  mounted () {
    googleAnalyticsV2.send({
      event: 'ftj-view',
      ftj_id: this.vacancy?.id,
    })
  },
  beforeDestroy () {
    if (!this.vacancyId) {
      this.clearVacancy()
      this.clearMoreVacancies()
    }
  },
  methods: {
    ...mapMutations({
      setNotFound: 'app/setNotFound',
      setRedirect: 'app/setRedirect',
      setPrefetched: 'vacancyDetails/setPrefetched',
      clearVacancy: 'vacancyDetails/clearVacancy',
      clearMoreVacancies: 'vacancyDetails/clearMoreVacancies',
    }),
    ...mapActions({
      load: 'vacancyDetails/load',
      loadMoreVacancies: 'vacancyDetails/loadMoreVacancies',
      getCountries: 'countries/getCountries',
      getActiveRole: 'user/getActiveRole',
      getUserInfo: 'user/getUserInfo',
      setToken: 'app/setToken'
    }),
    async checkExtendPublication () {
      const token = this.$route.query.token
      const vacancyProlongationParam = this.$route.query.vacancyProlongation
      const vacancyUnarchiveParam = this.$route.query.vacancyUnarchive
      if (token && (vacancyProlongationParam || vacancyUnarchiveParam)) {
        let data = null
        if (vacancyProlongationParam) {
          data = await userUpdateMeta(token)
        } else {
          data = await vacancyUnArchive(this.$route.params.id, token)
        }
        const bearerToken = data?.token
        this.setToken(bearerToken)
        await Promise.all([this.getActiveRole(), this.getUserInfo()])
        this.load({ id: this.$route.params.id, slug: this.$route.params.slug, withLoading: false })
        this.openSnackbar({
          text: vacancyProlongationParam
            ? 'Awesome! Your job listing has been successfully updated and will continue to be active.'
            : 'Congratulations! Your job listing has been reactivated and will remain active.'
        })
      }
    },
  },
  metaInfo () {
    const title = this.metaTitle
    const meta = [
      {
        name: 'description',
        content: this.metaDescription,
      },
    ]
    const script = []
    if (this.vacancy) {
      const defaultLocations = META_COUNTRIES.map(name => ({
        '@type': 'Country', name
      }))
      const customer = this.vacancy.relations.Customer
      const skills: Skill[] = this.vacancy.relations.Skill?.filter((s: Skill) => s.id !== this.vacancy.category?.id)

      const mapLocation = (l: VacancyLocation) => {
        const data = {
          '@type': 'Place',
          address: {
            '@type': 'PostalAddress',
            addressCountry: l.country,
          }
        }
        if (l.country !== l.city) {
        // @ts-ignore
          data.address.addressRegion = l.city
        }
        return data
      }
      script.push({
        type: 'application/ld+json',
        json: {
          '@context': 'http://schema.org',
          '@type': 'JobPosting',
          title: this.name,
          description: stripDescriptionTags(this.vacancy.description, { stripLinks: true }),
          datePosted: this.isPublished
            ? formatDate(this.vacancy.first_published_at, 'YYYY-MM-DD')
            : formatDate(this.vacancy.created_at, 'YYYY-MM-DD'),
          validThrough: formatDate(addMonths(this.vacancy.first_published_at, 3), DATE_TIME_FORMAT_WITHOUT_SEC),
          employmentType: this.vacancy.position_type === PART_TIME ? 'PART_TIME' : 'FULL_TIME',
          ...((this.vacancy.position_remote && !this.vacancy.position_office) && {
            jobLocationType: 'TELECOMMUTE',
          }),
          ...(this.vacancy.position_office && this.vacancy.relations.Location?.length && {
            jobLocation: this.vacancy.relations.Location.map(mapLocation)
          }),
          applicantLocationRequirements: defaultLocations,
          hiringOrganization: {
            '@type': 'Organization',
            name: customer.name,
            ...(customer.avatar?.src && { logo: customer.avatar.src }),
            ...(customer.website && { sameAs: customer.website }),
          },
          ...(this.vacancy.salary_from && {
            baseSalary: {
              '@type': 'MonetaryAmount',
              currency: 'USD',
              value: {
                '@type': 'QuantitativeValue',
                unitText: 'YEAR',
                ...(this.vacancy.salary_type === SalaryTypes.FIXED && { value: this.vacancy.salary_from }),
                ...(this.vacancy.salary_type === SalaryTypes.RANGE && {
                  minValue: this.vacancy.salary_from,
                  maxValue: this.vacancy.salary_to
                }),
              },
            },
          }),
          ...(this.vacancy.category && { occupationalCategory: this.vacancy.category.name }),
          ...(skills?.length && { skills: skills.map(s => s.name).join(', ') }),
        }
      })
    }
    return {
      title,
      meta,
      script,
      link: [this.canonicalLink],
    }
  },
})
