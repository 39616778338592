import VacancyRole from '@/models-ts/vacancies/VacancyRole'
import { RootState } from '@/store'
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default Vue.extend<any, any, any, any>({
  props: {
    loading: Boolean,
    disabled: Boolean,
    readOnly: Boolean,
    value: Number,
  },
  data () {
    return {
      roleLoading: true,
      hasSearch: false,
    }
  },
  computed: {
    ...mapState<RootState>({
      predefinedRoles: (state: RootState) => state.vacancyRoles.roles.value || [],
    }),
    ...mapGetters({
      options: 'vacancyRoles/roleOptions',
    }),
    selected () {
      return this.value
        ? this.options.find((opt: VacancyRole) => opt.id === this.value)
        : null
    },
  },
  async created () {
    await this.getRoles()
    this.roleLoading = false
  },
  methods: {
    ...mapActions({
      getRoles: 'vacancyRoles/getRoles',
    }),
    onInput (option?: VacancyRole) {
      this.$emit('input', option)
      this.hasSearch = false
    },
    async validate () {
      this.errors.clear()
      return this.$validator.validateAll()
    },
    onSearch (search: string) {
      this.hasSearch = !!search
    },
  },
})
