import { BACKEND_PRIVATE, BACKEND_PUBLIC } from './base'

export enum ListPagesType {
  VACANCIES_LIST = 1,
}

export interface ListPagesRequest {
  type: ListPagesType
  slug?: string
}

export function getListPages (params: ListPagesRequest) {
  return BACKEND_PUBLIC.get('/sitemap/list-pages', { params })
}
