import { WorkExperience } from '@/constants/vacancies/experiences'
import Vue from 'vue'

const options = [
  { id: WorkExperience.YEAR, name: '< 1 year' },
  { id: WorkExperience.THREE_YEARS, name: '1-3 years' },
  { id: WorkExperience.MORE_THREE_YEARS, name: '3+ years' },
]

export default Vue.extend<any, any, any, any>({
  props: {
    loading: Boolean,
    disabled: Boolean,
    readOnly: Boolean,
    value: Number,
  },
  data () {
    return {
      options,
    }
  },
  computed: {
    selected () {
      return this.value
        ? this.options.find((opt: { id: number }) => opt.id === this.value)
        : null
    },
  },
  methods: {
    onInput (v: any) {
      this.$emit('input', v?.id || WorkExperience.NOT_SET)
    },
  },
})
