import Vue from 'vue'
import { BookmarkTypes } from '@/constants/bookmarks/bookmarkType'
import shareableMixin from '@/mixins/shareableMixin'
import BookmarkButton from '@/partials/BookmarkButtons/BookmarkButton/BookmarkButton.vue'
import actionsMixin from '../actionsMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [actionsMixin, shareableMixin],
  components: { BookmarkButton },
  data () {
    return {
      bookmarkType: BookmarkTypes.VACANCY,
    }
  },
})
