import Vue from 'vue'

const REMOTE_POSITION = { name: 'Remote' }
const OFFICE_POSITION = { name: 'Office' }
const HYBRID_POSITION = { name: 'Hybrid' }

export default Vue.extend<any, any, any, any>({
  props: {
    loading: Boolean,
    disabled: Boolean,
    readOnly: Boolean,
    positionRemote: Boolean,
    positionOffice: Boolean,
  },
  data () {
    return {
      options: [REMOTE_POSITION, OFFICE_POSITION, HYBRID_POSITION],
    }
  },
  computed: {
    selected () {
      if (this.positionRemote && this.positionOffice) {
        return HYBRID_POSITION
      } else if (this.positionRemote) {
        return REMOTE_POSITION
      } else if (this.positionOffice) {
        return OFFICE_POSITION
      }
      return null
    },
  },
  methods: {
    onInput (v: any) {
      if (!v) {
        this.$emit('input', {
          positionRemote: false,
          positionOffice: false
        })
      } else if (v.name === HYBRID_POSITION.name) {
        this.$emit('input', {
          positionRemote: true,
          positionOffice: true
        })
      } else {
        this.$emit('input', {
          positionRemote: v.name === REMOTE_POSITION.name,
          positionOffice: v.name === OFFICE_POSITION.name
        })
      }
    },
    async validate () {
      this.errors.clear()
      return this.$validator.validateAll()
    },
  },
})
