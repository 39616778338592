/* eslint-disable max-len */
export default [
  {
    question: 'How to get a crypto job',
    answer: `If you’re looking for a job in the Web3 world, it helps to have at least a broad understanding of the technology, as well as key currencies and projects. If you’re applying for a technical role, then you’ll need more in-depth experience. 
    You can look for jobs in various ways. You can start with the standard recruitment sites, like LinkedIn, on freelancer sites like LaborX, or you can get involved with individual projects and communities, if there is something you’re interested in and you have skills they need.
    `,
  },
  {
    question: 'How much do crypto workers make?',
    answer: 'Rates of pay in the Web3 industry are typically higher than comparable jobs elsewhere, even if they don’t require specific experience of blockchain. This is true of technical roles (including developers) and non-technical ones (e.g. marketing, communications and content creation). Where particular expertise of a technology is required, salaries can be substantially higher, with the top jobs easily paying $200,000+ per year.',
  },
  {
    question: 'What kind of jobs can I get in the cryptocurrency sector?',
    answer: 'There are all kinds of jobs available in the Web3 industry. As well as technical roles, which will require knowledge of popular programming languages and blockchain theory and platforms, there is a wide range of non-technical and support roles. These include marketing and communications, content creation, social media management, community management, administration and HR, research, trading, and legal and compliance roles.',
  },
  {
    question: 'What are blockchain jobs?',
    answer: 'The term ‘blockchain jobs’ is typically used to mean jobs of any kind within the crypto/blockchain/Web3 sector. It includes technical roles (e.g. blockchain developer, architect or engineer, data scientist, security expert, and so on) but also roles in any other area for an organisation that just happens to work with blockchain. ‘Blockchain jobs’ therefore may or may not require understanding of blockchain technology.'
  }
]
