import { render, staticRenderFns } from "./VacancyExternalUrl.htm?vue&type=template&id=39644979&scoped=true&"
import script from "./VacancyExternalUrl.ts?vue&type=script&lang=js&"
export * from "./VacancyExternalUrl.ts?vue&type=script&lang=js&"
import style0 from "./VacancyExternalUrl.scss?vue&type=style&index=0&id=39644979&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39644979",
  null
  
)

export default component.exports