import Vue, { PropType } from 'vue'
import { mapState } from 'vuex'
import striptags from 'striptags'
import unescape from 'lodash/unescape'
import Skill from '@/models-ts/Skill'
import { RootState } from '@/store'
import { aiAssistant } from '@/servicies-ts/AIAssistantService'

const DESC_MIN = 100
const DESC_MAX = 10000

interface Data {
  DESC_MIN: number
  DESC_MAX: number
  aiLoading: boolean
}

export default Vue.extend({
  props: {
    loading: Boolean,
    disabled: Boolean,
    readOnly: Boolean,
    value: String,
    name: String,
    skills: Array as PropType<Skill[]>,
  },
  data (): Data {
    return {
      DESC_MIN,
      DESC_MAX,
      aiLoading: false,
    }
  },
  computed: {
    ...mapState<RootState>({
      isLoggedIn: (state: RootState) => state.app.authorized,
    }),
    length () {
      return this.value ? striptags(this.value).length : 0
    },
    aiAssistantDisabled () {
      return this.skills?.length === 0 || !this.name
    },
    requiredSkills () {
      return this.skills?.map((skill: Skill) => skill.name)
    },
  },
  beforeDestroy () {
    aiAssistant.destroy()
  },
  methods: {
    async clickToAiBtn () {
      if (this.aiLoading) {
        return
      }
      try {
        this.aiLoading = true
        const description = await aiAssistant.generateDescription({
          name: this.name,
          skills: this.requiredSkills,
          type: 'vacancy',
        })
        this.onInput(description)
        // @ts-ignore
        this.$refs.editor?.setData?.(description)
      } finally {
        this.aiLoading = false
      }
    },
    onInput (v: string) {
      this.$emit('input', v)
    },
    validate () {
      this.errors?.clear()
      let descriptionLengthIsValid = true
      const description = unescape(striptags(this.value))
      if (description.length < DESC_MIN) {
        descriptionLengthIsValid = false
        this.errors?.add({
          field: 'value',
          msg: `The description field must be at least ${DESC_MIN} characters`,
        })
      } else if (description.length > DESC_MAX) {
        descriptionLengthIsValid = false
        this.errors?.add({
          field: 'value',
          msg: `The description field must not be greater than ${DESC_MAX} characters`,
        })
      }
      return descriptionLengthIsValid
    },
  },
})
