import Vue from 'vue'
import isEqual from 'lodash/isEqual'
import isEqualWith from 'lodash/isEqualWith'
import omit from 'lodash/omit'
import { mapActions, mapMutations, mapState } from 'vuex'
import ErrorMatcher from '@/utils/ErrorMatcher'
import Skill from '@/models-ts/Skill'
import Vacancy from '@/models-ts/vacancies/Vacancy'
import animateScrollTo from '@/utils/animateScrollTo'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import responseMixin from '@/mixins/responseMixin'
import canonicalMixin from '@/mixins/canonicalMixin'
import { Statuses } from '@/constants/vacancies/statuses'
import { VACANCY_ADD, VACANCY_DETAILS_ADMIN, VACANCY_EDIT, POST_A_JOB } from '@/constants/routes'
import { VacancyModerationStages } from '@/constants/vacancies/vacancyModerationStages'
import { createVacancy, getVacancy, publishVacancy, updateVacancy } from '@/api/vacancies'
import { parseSlug } from '@/utils-ts/parser'
import { Roles } from '@/constants/user/roles'
import { RootState } from '@/store'
import { SalaryTypes } from '@/constants/vacancies/SalaryTypes'
import VacancyDescription from './VacancyDescription/VacancyDescription.vue'
import VacancyTitle from './VacancyTitle/VacancyTitle.vue'
import { FieldError } from 'vee-validate'
import VacancyEmploymentType from './VacancyEmploymentType/VacancyEmploymentType.vue'
import VacancyWorkplaceType from './VacancyWorkplaceType/VacancyWorkplaceType.vue'
import VacancyLocation from './VacancyLocation/VacancyLocation.vue'
import VacancyWorkExperience from './VacancyWorkExperience/VacancyWorkExperience.vue'
import VacancySalary from './VacancySalary/VacancySalary.vue'
import VacancyExternalUrl from './VacancyExternalUrl/VacancyExternalUrl.vue'
import VacancySkills from './VacancySkills/VacancySkills.vue'
import VacancyRole from './VacancyRole/VacancyRole.vue'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, canonicalMixin],
  components: {
    VacancyDescription,
    VacancyTitle,
    VacancyEmploymentType,
    VacancyWorkplaceType,
    VacancyLocation,
    VacancyWorkExperience,
    VacancySalary,
    VacancyExternalUrl,
    VacancySkills,
    VacancyRole,
  },
  data () {
    return {
      POST_A_JOB,
      readOnly: false,
      preloading: false,
      saving: false,
      updating: false,
      publishing: false,
      vacancy: new Vacancy(),
      oldVacancy: new Vacancy(),
      errorFields: [],
    }
  },
  computed: {
    ...mapState<RootState>({
      isLoggedIn: (state: RootState) => state.app.authorized,
      customerIsNewbie: (state: RootState) => state.user?.customerIsNewbie,
      isOutsideCustomer: (state: RootState) => state.user?.isOutsideCustomer,
    }),
    canPublish () {
      return !this.vacancy.status || this.vacancy.status === Statuses.DRAFT
    },
    processing () {
      return this.saving || this.updating
    },
    isUpdated () {
      const compare = () => {
        const vacancy = omit(this.vacancy, ['relations.Skill'])
        const oldVacancy = omit(this.oldVacancy, ['relations.Skill'])
        const skills = this.vacancy.relations.Skill.map((s: Skill) => s.id)
        const oldSkills = this.oldVacancy.relations.Skill.map((s: Skill) => s.id)
        return isEqual(vacancy, oldVacancy) && isEqual(skills, oldSkills)
      }
      return !isEqualWith(this.vacancy, this.oldVacancy, compare)
    },
    pageTitle () {
      return this.$route.params.id
        ? 'Edit Full-time Job'
        : 'Create New Full-time Job'
    },
  },
  watch: {
    '$route.name': {
      async handler () {
        try {
          if (this.$route.name === VACANCY_ADD) {
            this.vacancy = new Vacancy()
            this.oldVacancy = new Vacancy()
            const editor = document.querySelector('.ck-editor__editable')
            // @ts-ignore
            editor && editor.ckeditorInstance.setData('')
          } else if (this.$route.name === VACANCY_EDIT) {
            this.preloading = true
            const vacancy = await getVacancy(this.$route.params.id)
            this.vacancy = Vacancy.fromServer(vacancy)
            this.oldVacancy = Vacancy.fromServer(vacancy)
            this.preloading = false
          }
        } catch (e) {
          if (ErrorMatcher.isNotFound(e)) {
            this.setNotFound(true)
          } else {
            this.parseError(e)
          }
        }
      },
    }
  },
  async created () {
    try {
      if (this.$route.params.id) {
        this.preloading = true
        const vacancy = await getVacancy(this.$route.params.id)
        this.vacancy = Vacancy.fromServer(vacancy)
        // if (!this.job.isNotStarted && this.$route.params.id) {
        //   this.$router.replace({ name: JOB_DETAILS, params: { id: this.$route.params.id, slug: this.job.slug } })
        //   return
        // }
        this.oldVacancy = Vacancy.fromServer(vacancy)
        this.preloading = false
      }
    } catch (e) {
      if (ErrorMatcher.isNotFound(e)) {
        this.setNotFound(true)
      } else {
        this.parseError(e)
      }
    }
  },
  methods: {
    ...mapMutations({
      setNotFound: 'app/setNotFound',
      setCustomerIsNewbie: 'user/setCustomerIsNewbie',
    }),
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    onWorkplaceTypeInput (value: { positionOffice: boolean, positionRemote: boolean }) {
      this.vacancy.position_office = value.positionOffice
      this.vacancy.position_remote = value.positionRemote
    },
    onRoleInput (value?: { id: number, name: string }) {
      this.vacancy.primaryRole = value?.id
      this.vacancy.primaryRoleName = value?.name
    },
    onSalaryInput (value: { type: SalaryTypes, from: string, to: string, isCrypto: boolean }) {
      this.vacancy.salary_type = value.type
      this.vacancy.salary_from = value.from
      this.vacancy.salary_to = value.to
      this.vacancy.payments_is_crypto = value.isCrypto
    },
    async onSaveDraftClick () {
      try {
        if (await this.validateAll()) {
          this.saving = true
          await this.saveVacancy()
          this.$router.push({ name: VACANCY_DETAILS_ADMIN, params: { id: this.vacancy.id, slug: this.vacancy.slug } })
        } else {
          this.validateFail()
        }
      } catch (e) {
        this.parseError(e)
      } finally {
        this.saving = false
      }
    },
    async onUpdateClick () {
      try {
        if (await this.validateAll()) {
          this.updating = true
          await this.updateVacancy()
          this.$router.push({ name: VACANCY_DETAILS_ADMIN, params: { id: this.vacancy.id, slug: this.vacancy.slug } })
        } else {
          this.validateFail()
        }
      } catch (e) {
        this.parseError(e)
      } finally {
        this.updating = false
      }
    },
    async onPublishClick () {
      try {
        if (await this.validateAll()) {
          this.publishing = true
          if (this.vacancy.id) {
            if (this.isUpdated) {
              await this.updateVacancy()
            }
            await this.publishVacancy()
          } else {
            await this.saveVacancy(true)
          }
          this.$router.push({ name: VACANCY_DETAILS_ADMIN, params: { id: this.vacancy.id, slug: this.vacancy.slug } })
        } else {
          this.validateFail()
        }
      } catch (e) {
        this.parseError(e)
      } finally {
        this.publishing = false
      }
    },
    async onSignUpClick () {
      if (await this.validateAll()) {
        try {
          this.publishing = true
          this.signupAndPublish()
        } finally {
          this.saving = false
        }
      } else {
        this.validateFail()
      }
    },
    async validateAll (publishing: boolean) {
      let isValid = true
      const fields = ['title', 'positionType', 'workplaceType', 'location', 'role', 'skills', 'salary', 'description', 'externalUrl']
      this.errorFields = []
      for (const field of fields) {
        const el = this.$refs[field]
        if (el?.validate) {
          const error = await el.validate(publishing) as FieldError
          if (!error) {
            isValid = false
            this.errorFields.push(field)
          }
        }
      }
      return isValid
    },
    validateFail () {
      if (this.errorFields.length) {
        animateScrollTo(this.$refs[this.errorFields[0]]?.$el)
      }
    },
    async saveVacancy (withPublish = false) {
      const { id, slug } = await notifiableRequest({
        request: async () => {
          const { id, slug, moderation_stage } = await createVacancy(this.vacancy.toServer())
          this.vacancy.moderation_stage = moderation_stage
          if (this.customerIsNewbie) {
            this.setCustomerIsNewbie(false)
          }
          if (withPublish) {
            await publishVacancy(id)
            googleAnalyticsV2.send({
              event: 'ftj-published',
              ftj_id: id,
            })
          }
          return { id, slug }
        },
        title: withPublish ? 'Publish job' : 'Save job',
        successText: withPublish
          ? this.vacancy.moderation_stage === VacancyModerationStages.MANUAL
            ? 'Your Job was successfully submitted for moderation. We will notify you of the outcome shortly.'
            : `Job: ${this.vacancy.name} was successfully published`
          : `Your Full-time Job has been successfully saved.`,
        failureText: (e) => {
          const ERR_MSG = 'Job saving error'
          return ErrorMatcher.isConflict(e) ? e.response.data.message || ERR_MSG : ERR_MSG
        }
      })
      this.vacancy.id = id
      this.vacancy.slug = parseSlug(slug)
    },
    async publishVacancy () {
      await notifiableRequest({
        request: () => publishVacancy(this.vacancy.id),
        successText: (vacancy) => vacancy.moderation_stage === VacancyModerationStages.MANUAL
          ? 'Your Job was successfully submitted for moderation. We will notify you of the outcome shortly.'
          : `Job: ${this.vacancy.name} was successfully published`,
        failureText: 'Error publishing Full-time Job. Please try again.'
      })
      googleAnalyticsV2.send({
        event: 'ftj-published',
        ftj_id: this.vacancy.id,
      })
    },
    async updateVacancy () {
      const { slug } = await notifiableRequest({
        request: async () => updateVacancy(this.vacancy.id, this.vacancy.toServer()),
        title: 'Update job',
        successText: 'You have successfully updated your Full-time Job.',
        failureText: 'Error updating Full-time Job. Please try again.'
      })
      this.vacancy.slug = parseSlug(slug)
    },
    async signupAndPublish () {
      this.openModal({
        component: 'lx-sign-up-modal',
        props: {
          predefinedRole: Roles.CUSTOMER,
          meta: { vacancy: this.vacancy.toServer() },
          fromType: 'fromJob',
          onSuccess: () => {
            this.readOnly = true
          },
        }
      })
    },
  },
  metaInfo () {
    return {
      title: this.preloading
        ? 'Loading'
        : (this.oldVacancy?.name ? `Edit '${this.oldVacancy.name}'` : 'Post Full-time Job'),
      link: [this.canonicalLink],
      meta: [
        {
          vmid: 'robots',
          name: 'robots',
          content: 'noindex',
        },
      ]
    }
  }
})
