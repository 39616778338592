import Vue, { PropType } from 'vue'
import throttle from 'lodash/throttle'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import { findPlaces } from '@/api/place'
import VacancyLocation from '@/models-ts/vacancies/VacancyLocation'

const MAX_LOCATIONS = 10

export default Vue.extend<any, any, any, any>({
  props: {
    loading: Boolean,
    disabled: Boolean,
    readOnly: Boolean,
    required: Boolean,
    value: {
      type: Array as PropType<Array<VacancyLocation>>,
      default: () => [],
    },
  },
  data () {
    return {
      options: [],
      citySearch: '',
      searching: false,
      throttledCitySearch: null,
    }
  },
  computed: {
    selected () {
      return this.value[this.value.length - 1] || null
    },
    selectedTags (): CloudyTag {
      return this.value.map((l: VacancyLocation) => ({ text: l.location }))
    },
  },
  created () {
    this.throttledCitySearch = throttle(async () => {
      const citySearch = this.citySearch
      const cities = await findPlaces({ search: this.citySearch, types: '(cities)' })
      if (citySearch === this.citySearch) {
        this.options = cities.map(city => new VacancyLocation({ city: city.cityName, country: city.countryName }))
        this.searching = false
      }
    }, 200)
  },
  methods: {
    addLocation (location: VacancyLocation) {
      if (!this.disabled) {
        const locations: Array<VacancyLocation> = this.value || []
        if (!locations.find(l => l.location === location.location)) {
          this.$emit('input', locations.concat(location))
        }
      }
    },
    onInput (location: VacancyLocation) {
      this.addLocation(location)
    },
    onDelete ({ tag }: { tag: CloudyTag }) {
      if (!this.disabled) {
        const index = this.value.findIndex((s: VacancyLocation) => s.location === tag.text)
        if (index !== -1) {
          let locations = [...this.value]
          locations.splice(index, 1)
          this.$emit('input', locations)
        }
      }
    },
    onOpen () {
      const params = {
        event: 'field-city',
        'event-action': 'start',
        'event-content': this.$route.params.id ? 'editing-fulltime-job' : 'creating-fulltime-job'
      }
      if (this.$route.params.id) {
        // @ts-ignore
        params.ftj_id = this.$route.params.id
      }
      googleAnalyticsV2.send(params)
    },
    onClose () {
      if (this.selected) {
        const params = {
          event: 'field-city',
          'event-action': 'finished',
          'event-content': this.$route.params.id ? 'editing-fulltime-job' : 'creating-fulltime-job'
        }
        if (this.$route.params.id) {
          // @ts-ignore
          params.ftj_id = this.$route.params.id
        }
        googleAnalyticsV2.send(params)
      }
    },
    async onSearchChange (v: string) {
      if (!this.citySearch) {
        this.searching = true
      }
      this.citySearch = v
      if (this.citySearch) {
        this.throttledCitySearch()
      }
    },
    validate () {
      this.errors.clear()
      if (this.value?.length > MAX_LOCATIONS) {
        this.errors.add({
          field: 'selected',
          msg: `The number of locations must not be greater than ${MAX_LOCATIONS}`
        })
        return false
      }
      if (this.required && !this.value?.length) {
        this.errors?.add({
          field: 'selected',
          msg: `The Location field is required`
        })
        return false
      }
      return true
    },
  },
})
