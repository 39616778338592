import Vue, { PropType } from 'vue'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import Skill from '@/models-ts/Skill'
import skillableMixin from '@/mixins/skillableMixin'
import { mapActions, mapGetters } from 'vuex'

const MAX_SKILLS = 7

export default Vue.extend<any, any, any, any>({
  mixins: [skillableMixin],
  props: {
    loading: Boolean,
    disabled: Boolean,
    readOnly: Boolean,
    value: {
      type: Array as PropType<Array<Skill>>,
      default: () => [],
    },
    isEdit: Boolean,
  },
  data () {
    return {
      skillsLoading: true,
      isMoreActive: false,
      hasSearch: false,
    }
  },
  computed: {
    ...mapGetters({
      categories: 'skills/getCategories',
      getRoots: 'skills/getRoots',
      getChilds: 'skills/getChilds'
    }),
    category () {
      return this.value.find((skill: Skill) => !skill.relations?.Parent.length)
    },
    options () {
      // if (this.category) {
      //   return this.getChilds(this.category.id)
      // }
      if (!this.hasSearch) {
        return this.categories
      }
      return this.predefinedSkills || []
    },
    selectedTags (): CloudyTag {
      return this.value.map((s: Skill) => ({ text: s.name }))
    },
    recommendedTags () {
      let skills = this.categories
      if (this.value.length > 0) {
        skills = this.categories.filter((opt: Skill) => !this.value.find((skill: Skill) => skill.id === opt.id))
      }
      return skills.map((s: Skill) => ({ text: s.name }))
    },
  },
  async created () {
    await this.getSkills()
    this.skillsLoading = false
  },
  methods: {
    ...mapActions({
      getSkills: 'skills/getSkills',
    }),
    addSkill (skill: Skill) {
      if (!this.disabled) {
        const skills: Array<Skill> = this.value || []
        if (!skills.find(s => s.id === skill.id)) {
          const newSkills = []
          if (!this.category && skill.relations?.Parent.length) {
            const parents = this.getRoots(skill)
            const category = parents.find((p: Skill) => !p.relations?.Parent.length && !this.value.find((s: Skill) => s.id === p.id))
            if (category) {
              newSkills.push(category)
            }
          }
          newSkills.push(skill)
          this.$emit('input', skills.concat(newSkills))
          const params = {
            event: 'category-selected',
            name: skill.name,
          }
          if (this.$route.params.id) {
            // @ts-ignore
            params.ftj_id = this.$route.params.id
          }
          googleAnalyticsV2.send(params)
        }
      }
    },
    onInput (skill: Skill) {
      this.addSkill(skill)
      this.hasSearch = false
    },
    onSearch (search: string) {
      this.hasSearch = !!search
    },
    onDelete ({ tag }: { tag: CloudyTag }) {
      if (!this.disabled) {
        const index = this.value.findIndex((s: Skill) => s.name === tag.text)
        if (index !== -1) {
          let skills = [...this.value]
          const skill: Skill = this.value[index]
          skills.splice(index, 1)
          // if (!skill.relations?.Parent.length) {
          //   const childs = this.getChilds(skill.id)
          //   skills = skills.filter(s => !childs.find((c: Skill) => c.id === s.id))
          // }
          this.$emit('input', skills)
        }
      }
    },
    onRecommendedClick ({ tag }: { tag: CloudyTag }) {
      const skill = this.predefinedSkills.find((s: Skill) => s.name === tag.text)
      if (skill) {
        this.addSkill(skill)
      }
    },
    validate () {
      this.errors.clear()
      if (!this.value?.length) {
        this.errors?.add({
          field: 'value',
          msg: `The Skills field is required`
        })
        return false
      }
      if (this.value?.length > MAX_SKILLS) {
        this.errors.add({
          field: 'value',
          msg: `The number of skills  must not be greater than ${MAX_SKILLS}`
        })
        return false
      }
      return true
    },
    onClickMore () {
      this.isMoreActive = !this.isMoreActive
    },
  },
})
