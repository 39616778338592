import { VACANCY_DETAILS } from '@/constants/routes'
import { isCrawler } from '@/utils-ts/strings'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import lxAnalytics from '@/servicies-ts/analytics/LxAnalytics'

export default Vue.extend({
  computed: {
    ...mapGetters({
      vacancy: 'vacancyDetails/vacancy',
    }),
  },
  async created () {
    try {
      if (isCrawler(window.navigator.userAgent)) {
        this.goBack()
        return
      }
      if (!this.vacancy) {
        await this.load({ id: this.$route.params.id, slug: this.$route.params.slug })
      }
      if (this.vacancy.external_url) {
        lxAnalytics.send('external-vacancy-redirect', { isBot: false })
        window.opener = null
        window.location.replace(this.vacancy.external_url)
      } else {
        this.goBack()
      }
    } catch (e) {
      console.error('Error fetching vacancy', e)
      this.goBack()
    }
  },
  methods: {
    ...mapActions({
      load: 'vacancyDetails/load',
    }),
    goBack () {
      lxAnalytics.send('external-vacancy-redirect', { isBot: true })
      this.$router.replace({ name: VACANCY_DETAILS, params: { id: this.$route.params.id, slug: this.$route.params.slug } })
    },
  },
  metaInfo: {
    title: 'Apply for Full-time Job',
    meta: [
      {
        vmid: 'robots',
        name: 'robots',
        content: 'noindex, nofollow',
      },
    ]
  },
})
