import Vue from 'vue'
import { mapState } from 'vuex'
import { DASHBOARD, LANDING, JOB_ADD, VACANCY_ADD } from '@/constants/routes'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import JobTypeButton from './JobTypeButton/JobTypeButton.vue'
import { AppState } from '@/store/shared/modules/app/types'
import { RootState } from '@/store'

export default Vue.extend<any, any, any, any>({
  components: {
    JobTypeButton,
  },
  data () {
    return {
      selected: null,
      DASHBOARD,
      LANDING,
    }
  },
  computed: {
    ...mapState<RootState>('app', {
      isLoggedIn: (state: AppState): boolean => state.authorized,
    }),
  },
  methods: {
    onSubmit () {
      const name = this.selected === 1 ? JOB_ADD : VACANCY_ADD
      googleAnalyticsV2.send({
        event: 'choose-type-post-job',
        'event-content': this.selected === 1 ? 'freelance_job' : 'full_time_job',
      })
      this.$router.push({ name })
    },
    onInputJobType (type: number) {
      this.selected = type
      googleAnalyticsV2.send({
        event: this.selected === 1
          ? 'post_job_free_click'
          : 'post_job_full_click',
        'event-content': this.selected === 1 ? 'freelance_job' : 'full_time_job',
      })
    },
  },
  metaInfo: {
    title: 'Post a Job',
  },
})
