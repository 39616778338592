import Vue from 'vue'

const MAX_LENGTH_TITLE = 255

export default Vue.extend<any, any, any, any>({
  props: {
    loading: Boolean,
    disabled: Boolean,
    readOnly: Boolean,
    value: String,
  },
  computed: {
    validationRule () {
      return `required|max:${MAX_LENGTH_TITLE}`
    },
  },
  methods: {
    onInput (v: string) {
      this.$emit('input', v)
    },
    onBlur () {
      if (this.value) {
        this.$emit('input', this.value.trim())
      }
    },
    async validate () {
      this.errors.clear()
      return this.$validator.validateAll()
    },
  },
})
