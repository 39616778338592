import { SalaryTypes } from '@/constants/vacancies/SalaryTypes'
import Vue, { PropType } from 'vue'

export default Vue.extend<any, any, any, any>({
  props: {
    loading: Boolean,
    disabled: Boolean,
    readOnly: Boolean,
    type: Number as PropType<SalaryTypes>,
    from: Number,
    to: Number,
    isCrypto: Boolean,
  },
  data () {
    return {
      annually: true,
    }
  },
  computed: {
    isFixed () {
      return this.type === SalaryTypes.FIXED
    },
    salaryFrom () {
      return this.annually
        ? this.from
        : Math.floor(this.from / 12)
    },
    salaryTo () {
      return this.annually
        ? this.to
        : Math.floor(this.to / 12)
    },
    errorMsg () {
      const error = this.errors.items.find((err: any) => err.field === 'salaryFrom' || err.field === 'salaryTo')
      return error ? error.msg : ''
    },
  },
  methods: {
    onMonthlyClick () {
      if (!this.readOnly) {
        this.annually = false
      }
    },
    onAnnuallyClick () {
      if (!this.readOnly) {
        this.annually = true
      }
    },
    onFixedClick () {
      if (!this.readOnly) {
        this.emitInput({ type: SalaryTypes.FIXED })
      }
    },
    onRangedClick () {
      if (!this.readOnly) {
        this.emitInput({ type: SalaryTypes.RANGE })
      }
    },
    onSwitchInput () {
      this.annually = !this.annually
    },
    onSalaryFromInput (from: number) {
      this.emitInput({ from: this.annually ? from : from * 12 })
    },
    onSalaryToInput (to: number) {
      this.emitInput({ to: this.annually ? to : to * 12 })
    },
    onPaymentSwitchClick () {
      this.emitInput({ isCrypto: !this.isCrypto })
    },
    emitInput (value: { type?: SalaryTypes, from?: string, to?: string, isCrypto?: boolean }) {
      this.$emit('input', {
        type: this.type,
        from: this.from,
        to: this.to,
        isCrypto: this.isCrypto,
        ...value,
      })
    },
    async validate () {
      this.errors.clear()
      let salaryFromIsValid = true
      const salaryValidation = this.annually
        ? {
          label: '999,999',
          value: 999999,
        }
        : {
          label: '83,333',
          value: 83333,
        }
      if (
        (this.salaryFrom || (this.salaryTo && this.type === SalaryTypes.RANGE)) &&
        (this.salaryFrom < 1 || this.salaryFrom > salaryValidation.value)
      ) {
        salaryFromIsValid = false
        this.errors.add({
          field: 'salaryFrom',
          msg: `The provided values must be at least $1 and not be greater than $${salaryValidation.label}`
        })
      }

      let salaryToIsValid = true
      if (this.salaryTo && (this.salaryTo < 1 || this.salaryTo > salaryValidation.value)) {
        salaryToIsValid = false
        this.errors.add({
          field: 'salaryTo',
          msg: `The provided values must be at least $1 and not be greater than $${salaryValidation.label}`
        })
      } else if (this.type === SalaryTypes.RANGE && this.salaryTo < this.salaryFrom) {
        salaryToIsValid = false
        this.errors.add({
          field: 'salaryTo',
          msg: 'The salary to field must be no less than salary from'
        })
      }
      return salaryFromIsValid && salaryFromIsValid
    },
  },
})
