import { render, staticRenderFns } from "./VacancyDescription.htm?vue&type=template&id=886ce85e&scoped=true&"
import script from "./VacancyDescription.ts?vue&type=script&lang=js&"
export * from "./VacancyDescription.ts?vue&type=script&lang=js&"
import style0 from "./VacancyDescription.scss?vue&type=style&index=0&id=886ce85e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "886ce85e",
  null
  
)

export default component.exports