import Vue from 'vue'

export default Vue.extend<any, any, any, any>({
  props: {
    title: String,
    description: String,
    value: Number,
    type: Number,
  },
})
