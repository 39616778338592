import { POSITION_TYPES, PositionType } from '@/constants/vacancies/positionTypes'
import Vue from 'vue'

export default Vue.extend<any, any, any, any>({
  props: {
    loading: Boolean,
    disabled: Boolean,
    readOnly: Boolean,
    value: Number,
  },
  data () {
    return {
      options: Object.values(POSITION_TYPES),
    }
  },
  computed: {
    selected () {
      return POSITION_TYPES[this.value as PositionType] || null
    },
  },
  methods: {
    onInput (v: any) {
      this.$emit('input', v?.id)
    },
    async validate () {
      this.errors.clear()
      return this.$validator.validateAll()
    },
  },
})
