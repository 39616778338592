import { PROXY_PUBLIC } from '@/api/base'

export interface Place {
  cityId: string
  cityName: string
  countryName: string
  description: string
}

export async function findPlaces ({ search, types }: { search: string, types?: string }): Promise<Array<Place>> {
  return PROXY_PUBLIC.get('/place/find', { params: { search, types } })
}
